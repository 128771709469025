import React, { useState } from 'react';
import {makeStyles, rgbToHex} from '@material-ui/core';
import Map from '../Map/Map';
import Sidebar, { SIDEBAR_WIDTH } from '../Sidebar/Sidebar';
import CoverHero from '../Cover/CoverHero';
import CoverFooter from '../Cover/CoverFooter';
import CoverHeader from '../Cover/CoverHeader';
import CoverLegend from '../Cover/CoverLegend';
import Header from '../Header/Header';
import {useAppState} from '../../state';
import slides from '../../slides';
import { calculatePickingColors } from '@deck.gl/layers/dist/es5/geojson-layer/geojson-binary';
import { ImageSource } from 'mapbox-gl';
import CoverBase from '../Cover/CoverBase';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflow: 'hidden'
    }
  },
  root: {
    display: 'flex',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(78)
    },
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(83)
    },
    backgroundColor: '#0e0e0e'
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - ' + SIDEBAR_WIDTH.xs + ')',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - ' + SIDEBAR_WIDTH.lg + ')',
    },
    height: '100%'
  },
  contentFull: {
    width: '100%',
    height: '100%'
  },
  imageContent: {
    display: 'block',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'sticky',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }
}));

const Main = () => {
  const classes = useStyles();
  const {currentSlide} = useAppState();
  const [imageSrc, setImageSrc] = useState(null);

  const loadImage = () => {
    import(`../../assets/images/slide${currentSlide}.jpg`).then(imageModule => {
      setImageSrc(imageModule.default);
    });
  };
  
  if (currentSlide !== null) {
    const {embed, image} = slides[currentSlide];
    let embedURL;
    if (embed) {
      embedURL = `https://gcp-us-east1.app.carto.com/map/${slides[currentSlide].mapID}`;
    }
    else if (image) {
      loadImage();
    }
    return (
      <div className={classes.root}>
        <div className={currentSlide > 0 ? classes.content : classes.contentFull}>
          {embed
            ? <iframe style={{width: '100%', height: '100%'}} src={embedURL}></iframe>
            : image 
              ? imageSrc && <div style={{ backgroundImage: `url('${imageSrc}')` }} className={classes.imageContent}></div>
              : <Map />
          }
        </div>
        <CoverHeader />
        <CoverBase>
          <CoverHero />
          <CoverFooter />          
        </CoverBase>
        {/* <CoverLegend /> */}
        <Sidebar />
        <Header hidden={currentSlide > 0} hideDelay={500} showDelay={0} />
      </div>
    );
  }
  else {
    return <></>;
  }
};

export default Main;
