export default [
  /* 0 */ {
    mapID: '48196ceb-d2e8-4275-89c4-453e37d5de9e',
    parameters: {depthTest: false},
  },
  /* 1 */ {
    mapID: '48196ceb-d2e8-4275-89c4-453e37d5de9e',
    parameters: {depthTest: false},
    orbit: true
  },
  /* 2 */ {
    mapID: '1f8a9928-6956-4cb7-be87-1cc6907ea1eb',
    props: {weightsTextureSize: 256}
  },
  /* 3 */ {
    mapID: 'dc61e15b-1bee-499c-8d77-cbb4469db655',
    orbit: true,
    props: { coverage: 0.7 }
  },
  /* 4 */ {
    mapID: '64d565b6-d0cf-4a01-af36-b697999e5c8e',
  },
  /* 5 */ {
    mapID: '4665e8ac-9593-4088-8d3e-888e1b4e13b2',
  },
  /* 6 */ {
    mapID: '0d6c5c23-ab99-49a8-afbf-8a2753eb0ad2',
    props: { coverage: 0.7 }
  },
  /* 7 */ {
    mapID: '7a82f73f-65af-422d-a997-81875261f4f4',
    props: { coverage: 0.7 }
  }
];
